import {isFunction} from '../utils/utils'

const Td = ({val}) => {
  const {value, render = null, className = '', props = {}, translatable = true} = val
  return (
    <td className={`${className} ${translatable ? '' : 'notranslate'}`} {...props}>
      {isFunction(render) ? render() : value}
    </td>
  )
}

export default Td
