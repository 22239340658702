
const AssinaturaRows = (props: any) => {
  const {item, setSignature, sendProofOfPayment, paySubscription, config} = props

  return (
    <tr>
      <td>#{item.id}</td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='badge badge-primary notranslate'>{item.plan.nome}</div>
        </div>
      </td>
      <td>
        {(item.ativo == 1 && <div className='badge badge-success'>Ativo</div>) || (
          <div className='badge badge-danger'>Inativo</div>
        )}
      </td>
      <td>{config.moeda.prefixo} <span className='notranslate'>{item.plan.valor}</span></td>
      <td>{config.moeda.prefixo} <span className='notranslate'>{item.ganho_atual}</span></td>
      <td>{item.data_resposta !== null ? item.data_resposta : 'N/D'}</td>
      <td className=''>
        {item.ativo !== 1 && (
          <>
            <button className='btn btn-danger me-3' onClick={() => setSignature(item)}>
              Cancelar
            </button>
            {item.method === 'Pix' && (
              <button className='btn btn-success me-3' onClick={() => sendProofOfPayment(item)}>
                Comprovante
              </button>
            )}
            <button className='btn btn-primary' onClick={() => paySubscription(item)}>
              Pagar
            </button>
          </>
        )}
      </td>
    </tr>
  )
}

export {AssinaturaRows}
