import React from 'react'
import {idGenerator} from '../../utils/idGenerator'

const InputFile = (props) => {
  const {
    name = '',
    value = null,
    label = null,
    containerAttributes = {},
    labelAttributes = {},
    withThumb = true,
    thumb = null,
  } = props

  var data = {
    className: 'form-control',
    name: name,
    type: 'file',
    value: value,
    ...props,
  }

  if (!data.errors.valido) {
    data.className += ' border-danger'
  }

  const container = {
    className: 'form-group',
    ...containerAttributes,
  }

  const labelDt = {
    className: 'form-label',
    htmlFor: data.id,
    ...labelAttributes,
  }

  delete data.setValue
  delete data.labelAttributes
  delete data.containerAttributes
  delete data.validation
  delete data.mask

  return (
    <div {...container}>
      {label && <label {...labelDt}>{label}</label>}
      {withThumb ? (
        <>
          <div className='d-flex align-items-center'>
            <div className='d-block me-5 symbol symbol-40px'>
              <img src={thumb} alt='Imagem' />
            </div>
            <input {...data} />
          </div>
          {!data.errors.valido && <span className='fs-small text-danger'>{data.errors.texto}</span>}
        </>
      ) : (
        <>
          <input {...data} />
          {!data.errors.valido && <span className='fs-small text-danger'>{data.errors.texto}</span>}
        </>
      )}
    </div>
  )
}

export default InputFile
