import {useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {sendRequest} from '../../../Helpers/Requests'
import {formatUsers} from '../../../Helpers/Utils'
import {PageTitle} from '../../../_metronic/layout/core'
import RedeContext from '../../contexts/RedeContext'
import {Grid, Paper, Typography} from '@material-ui/core'
import RedeUser from '../../components/RedeUser'
import RedeSeparador from '../../components/RedeSeparador'
import '../../components/index.css'
import {useAuth} from '../../modules/auth'

const MinhaRede = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [rede, setRede] = useState<any>([])
  const [raiz, setRaiz] = useState<any>([])

  const getRede = async (userId = null) => {
    const req = await sendRequest(`rede/visualizar${userId ? '/'+userId : ''}`, 'GET')

    const request = await req

    if (request !== null) {
      setRede(formatUsers(request))

      setRaiz(request.formato.raiz)
    }
  }

  const getUsuario = async (user: any) => {
    if (user.id == currentUser?.id) return true;

    getRede(user.id)
  }

  const getUserCima = async (user: any) => {
    if (user.id == currentUser?.id) return true;
    
    getRede(user.id_link)
  }

  useEffect(() => {
    getRede()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Minha Rede</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush '>
            <div className='card-body d-flex align-items-end pt-5 overflow-auto'>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className='Rede--users'>
                  <div className='Rede--linha'>
                    <RedeUser
                      index={0}
                      clickUser={getUserCima}
                      popperPlacement='bottom'
                      rede={rede}
                      setRede={setRede}
                    />
                  </div>
                  <RedeSeparador tipo='' />

                  <div className='Rede--linha'>
                    <RedeUser
                      index={1}
                      clickUser={getUsuario}
                      popperPlacement='bottom'
                      rede={rede}
                      setRede={setRede}
                    />
                    <RedeUser
                      index={2}
                      clickUser={getUsuario}
                      popperPlacement='bottom'
                      rede={rede}
                      setRede={setRede}
                    />
                  </div>

                  <RedeSeparador tipo='_meio' />
                  <RedeSeparador tipo='_meio' />

                  <div className='Rede--linha'>
                    <div className='Rede--coluna'>
                      <RedeUser
                        index={3}
                        clickUser={getUsuario}
                        popperPlacement='bottom-start'
                        rede={rede}
                        setRede={setRede}
                      />
                      <RedeUser
                        index={4}
                        clickUser={getUsuario}
                        popperPlacement='bottom'
                        rede={rede}
                        setRede={setRede}
                      />
                    </div>
                    <div className='Rede--coluna'>
                      <RedeUser
                        index={5}
                        clickUser={getUsuario}
                        popperPlacement='bottom'
                        rede={rede}
                        setRede={setRede}
                      />
                      <RedeUser
                        index={6}
                        clickUser={getUsuario}
                        popperPlacement='bottom-end'
                        rede={rede}
                        setRede={setRede}
                      />
                    </div>
                  </div>
                  <div className='Rede--linha_separador_meio'>
                    <RedeSeparador tipo='_meio' />
                    <RedeSeparador tipo='_meio' />
                  </div>
                  <div className='Rede--linha_separador_meio'>
                    <RedeSeparador tipo='_meio' />
                    <RedeSeparador tipo='_meio' />
                  </div>

                  <div className='Rede--linha'>
                    <div className='Rede--coluna'>
                      <div className='Rede--coluna'>
                        <RedeUser
                          index={7}
                          clickUser={getUsuario}
                          popperPlacement='bottom-start'
                          rede={rede}
                          setRede={setRede}
                        />
                        <RedeUser
                          index={8}
                          clickUser={getUsuario}
                          popperPlacement='bottom-start'
                          rede={rede}
                          setRede={setRede}
                        />
                      </div>
                      <div className='Rede--coluna'>
                        <RedeUser
                          index={9}
                          clickUser={getUsuario}
                          popperPlacement='bottom'
                          rede={rede}
                          setRede={setRede}
                        />
                        <RedeUser
                          index={10}
                          clickUser={getUsuario}
                          popperPlacement='bottom'
                          rede={rede}
                          setRede={setRede}
                        />
                      </div>
                    </div>

                    <div className='Rede--coluna'>
                      <div className='Rede--coluna'>
                        <RedeUser
                          index={11}
                          clickUser={getUsuario}
                          popperPlacement='bottom'
                          rede={rede}
                          setRede={setRede}
                        />
                        <RedeUser
                          index={12}
                          clickUser={getUsuario}
                          popperPlacement='bottom'
                          rede={rede}
                          setRede={setRede}
                        />
                      </div>
                      <div className='Rede--coluna'>
                        <RedeUser
                          index={13}
                          clickUser={getUsuario}
                          popperPlacement='bottom-end'
                          rede={rede}
                          setRede={setRede}
                        />
                        <RedeUser
                          index={14}
                          clickUser={getUsuario}
                          popperPlacement='bottom-end'
                          rede={rede}
                          setRede={setRede}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {MinhaRede}
