import React from 'react'
import {idGenerator} from '../../utils/idGenerator'

const InputSelect = (props) => {
  const {
    name = '',
    value = null,
    label = null,
    containerAttributes = {},
    labelAttributes = {},
    options = [],
  } = props

  var data = {
    className: 'form-control',
    name: name,
    value: value,
    ...props,
  }

  if (!data.errors.valido) {
    data.className += ' border-danger'
  }

  const container = {
    className: 'form-group',
    ...containerAttributes,
  }

  const labelDt = {
    className: 'form-label',
    htmlFor: data.id,
    ...labelAttributes,
  }

  delete data.setValue
  delete data.labelAttributes
  delete data.containerAttributes
  delete data.options
  delete data.validation
  delete data.mask

  return (
    <div {...container}>
      {label && <label {...labelDt}>{label}</label>}
      <select {...data}>
        {options.map((opt, index) => (
          <option value={opt.value} key={`option_${data.id}_${index}`} >{opt.label}</option>
        ))}
      </select>
      {!data.errors.valido && <span className='fs-small text-danger'>{data.errors.texto}</span>}
    </div>
  )
}

export default InputSelect
