import {getEmptyRow} from '../utils/utils'
import Td from './Td'

const Row = ({row, rowModel, columns, loading, index}) => {
  const values = loading ? getEmptyRow(columns) : rowModel(row)
  return (
    <tr
      className={loading ? 'loadingRow bg-gray-300' : ''}
      style={loading && index > 0 ? {animationDelay: `${index * 0.2}s`} : {}}
    >
      {values.map((val, index) => (
        <Td key={`custom-table-data-${index}`} val={val} />
      ))}
    </tr>
  )
}

export default Row
