import React from 'react'
import {idGenerator} from '../../utils/idGenerator'

const InputText = (props) => {
  const {
    name = '',
    value = null,
    label = null,
    containerAttributes = {},
    labelAttributes = {},
  } = props

  var data = {
    className: 'form-control',
    name: name,
    type: 'text',
    value: value,
    ...props,
  }

  if (!data.errors.valido) {
    data.className += ' border-danger'
  }

  const container = {
    className: 'form-group',
    ...containerAttributes,
  }

  const labelDt = {
    className: 'form-label',
    htmlFor: data.id,
    ...labelAttributes,
  }

  delete data.setValue
  delete data.labelAttributes
  delete data.containerAttributes
  delete data.validation
  delete data.mask

  return (
    <div {...container}>
      {label && <label {...labelDt}>{label}</label>}
      <input {...data} />
      {!data.errors.valido && <span className='fs-small text-danger'>{data.errors.texto}</span>}
    </div>
  )
}

export default InputText
