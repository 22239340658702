/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {sendRequest} from '../../../../Helpers/Requests'
import {toast} from 'react-hot-toast'
import Swal from 'sweetalert2'
import Form from '../../form/components/Form'
import getRegistrationInputs from '../../../../Helpers/RegistrationInputs'
import ConfigContext from '../../../contexts/Config/ConfigContext'
import LanguageSelector from '../../gTranslator/components/LanguageSelector'
import {ModalPagamentoCadastro} from './ModalPagamentoCadastro'
import {ModalComprovanteCadastro} from './ModalComprovanteCadastro'

export function Registration() {
  const navigate = useNavigate()
  const {hash} = useParams()
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [payModal, setPayModal] = useState(false)
  const [userData, setUserData] = useState<any>(null)
  const [user, setUser] = useState<any>(null)
  const config = useContext(ConfigContext)

  const onSubmit = async (values: any) => {
    if (!config.licenca) {
      return saveCadastro({...values, url: hash})
    }
    setUserData({...values, url: hash})
    setPayModal(true)
  }

  const saveCadastro = async (data: any) => {
    setLoading(true)

    try {
      const response = await sendRequest('/usuario/cadastrar', 'POST', data)

      if (!response) {
        setLoading(false)
        return false
      }

      toast.success('Cadastro realizado com sucesso, você já pode fazer login!')
      navigate('/auth')
    } catch (e) {
      setLoading(false)
    }
  }

  const [indicador, setIndicador] = useState('')
  const [perna, setPerna] = useState('')
  const [licenca, setLicenca] = useState<any>(null)
  const [paymentButtons, setPaymentButtons] = useState<any>(null)

  const getData = async () => {
    const dados = await sendRequest(`/usuario/url/${hash}`, 'GET')

    if (dados !== null) {
      let login = dados.usuario.login
      login = login.charAt(0).toUpperCase() + login.slice(1)
      setIndicador(login)
      setPerna(dados.perna)
    }

    if (config.licenca) {
      const dadosLicenca = await sendRequest(`/licenca`, 'GET')
      if (dadosLicenca !== null) {
        setLicenca(parseFloat(dadosLicenca.valor))
        setPaymentButtons(dadosLicenca.paymentMethods)
      }
    }
    setInitialized(true)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Cadastro</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>
          Preencha o formulário abaixo para realizar seu cadastro!
        </div>
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}
      <div className='row g-1 mb-4 justify-content-center flex-nowrap align-items-center'>
        {/* begin::Col */}
        {config.indicacao && (
          <div style={{flex: 1}}>
            <div className='alert alert-success mb-0'>
              Indicador: <strong className='notranslate'>{indicador}</strong>
            </div>
          </div>
        )}
        {/* end::Col */}

        {/* begin::Col */}
        {config.binario && (
          <div style={{flex: 1}}>
            <div className='alert alert-primary mb-0'>
              Perna: <strong>{perna}</strong>
            </div>
          </div>
        )}

        <LanguageSelector
          containerClassName='fv-row w-auto'
          labelClassName='d-none'
          selectClassName='form-select form-gTranslate bg-transparent'
          optionLabelFullDescription={false}
        />
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {initialized && (
        <>
          <Form
            inputs={getRegistrationInputs(licenca)}
            attributes={{
              className: 'row g-3',
            }}
            onSubmit={onSubmit}
            submitButtonProps={{
              className: 'btn btn-lg btn-primary w-100 mb-0',
            }}
            submitButtonChildren={
              <>
                {!loading && <span className='indicator-label'>Finalizar Cadastro</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </>
            }
          />

          <ModalPagamentoCadastro
            paymodal={payModal}
            hidePaymodal={() => setPayModal(false)}
            userData={userData}
            user={user}
            setUser={setUser}
            licenca={licenca}
            paymentButtons={paymentButtons}
          />
        </>
      )}
    </>
  )
}
