import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
const ModalCancelamento = (props: any) => {
  const {signature, setSignature, handleCancel, config} = props

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={signature != null}
      onHide={() => setSignature(null)}
      backdrop={true}
    >
      {signature != null && (
        <>
          <div className='modal-header'>
            <h2>Cancelar Pagamento</h2>
            {/* begin::Close */}
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setSignature(null)}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
            {/* end::Close */}
          </div>
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='alert alert-danger'>
              <strong>ATENÇÃO!</strong>
              <br />
              Ao remover o pagamento do plano, mesmo que ele seja concluído posteriormente, <br />
              <strong>O PLANO NÃO SERÁ ATIVADO!</strong>
            </div>
            <span className='text-dark' style={{fontSize: '1.2em', textAlign: 'center'}}>
              Deseja realmente prosseguir com o cancelamento do pagamento do plano{' '}
              <span className='notranslate'>{signature.plan.nome}</span> ?
            </span>

            <div className='row mt-5'>
              <div className='col-lg-6'>
                <button className='btn btn-danger' style={{width: '100%'}} onClick={handleCancel}>
                  Prosseguir
                </button>
              </div>
              <div className='col-lg-6'>
                <button
                  className='btn btn-primary'
                  style={{width: '100%'}}
                  onClick={() => setSignature(null)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export {ModalCancelamento}
