import {useState,useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {toast} from 'react-hot-toast'
import {fileToBase64WithoutHeadings} from '../../../Helpers/Utils'
import {sendRequest} from '../../../Helpers/Requests'
import { useNavigate } from 'react-router-dom'
import ConfigContext from '../../contexts/Config/ConfigContext'

const ModalComprovante = (props: any) => {
  const navigate = useNavigate()
  const {paymentProofModal, hidePaymentProofModal, subscription, redirectAfter=null, config} = props
  const [base64File, setBase64File] = useState<string | null>(null)
  const [sending, setSending] = useState<boolean>(false)

  const configure = useContext(ConfigContext)

  const changedFile = async (e: any) => {
    if (typeof e.target.files[0] === 'undefined') {
      setBase64File(null)
      return
    }

    const base64 = await fileToBase64WithoutHeadings(e.target.files[0])
    setBase64File(base64)
  }

  const sendPaymentProof = async () => {
    if (!base64File)
      return toast.error('Por favor, selecione o comprovante antes de tentar enviá-lo.')

    setSending(true)
    const response = await sendRequest(`planos/comprovante/${subscription.id}`, 'POST', {
      comprovante: base64File,
    })

    if (response.tipo === 'success') {
      toast.success(response.msg)
      hidePaymentProofModal()
    }

    if (redirectAfter !== null) return navigate(redirectAfter);

    setSending(false)
    setBase64File(null)
  }

  if (!subscription) return <></>

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={paymentProofModal}
      onHide={hidePaymentProofModal}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Enviar Comprovante</h2>
        {/* begin::Close */}
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={hidePaymentProofModal}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='row mt-2'>
          <div className='col-lg-12'>
            <h4>Plano: <span className='notranslate'>{subscription.plan.nome}</span></h4>
            <span>Valor: {configure.moeda.prefixo}{(subscription.difference !== null)?subscription.difference.toFixed(2):subscription.plan.valor.toFixed(2)}</span>
            <p>Envie abaixo o comprovante de pagamento via PIX do seu plano:</p>
            {paymentProofModal && (
              <input
                type='file'
                className='form-control'
                name='comprovante'
                accept='image/png,image/jpeg,image/jpg,image/bmp,image/git,application/pdf'
                onChange={changedFile}
                id='kt_create_account_form_account_type_personal'
              ></input>
            )}
          </div>
        </div>
      </div>

      <div className='modal-footer py-lg-10 px-lg-10'>
        <button
          type='reset'
          className='btn btn-secondary btn-block'
          onClick={() => sendPaymentProof()}
          disabled={sending}
          style={{width: '100%', marginTop: '0.5rem'}}
        >
          {sending ? (
            <>
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde um instante...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </>
          ) : (
            <>Enviar Comprovante</>
          )}
        </button>
        <button className='btn btn-danger' style={{width: '100%'}} onClick={hidePaymentProofModal}>
          Cancelar
        </button>
      </div>
    </Modal>
  )
}

export {ModalComprovante}
