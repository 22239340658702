import {CircularProgress} from '@mui/material'
import {useState, useEffect} from 'react'
import Column from './components/Column'
import Row from './components/Row'
import {initialRows, isValid} from './utils/utils'
import './scss/table.scss'

const Table = ({
  columns,
  rowModel,
  data = null,
  hasSearch = true,
  sortable = true,
  fetchUrl = null,
  searchUrl = null,
  paginate = true,
  rowsPerPage = 5,
  searchColumns = [],
  initialSort = 0,
  initialOrder = 'asc',
}) => {
  const loadingRowsNumber = rowsPerPage < 15 ? 15 : rowsPerPage
  const [sortIndex, setSortIndex] = useState(initialSort)
  const [sortOrder, setSortOrder] = useState(initialOrder)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [searchRows, setSearchRows] = useState(null)
  const [rows, setRows] = useState(initialRows(loadingRowsNumber, columns))
  const [loading, setLoading] = useState(true)

  /* SORT FUNCTION */
  const sortSync = (index) => {
    let sorting = 'desc'
    if (sortIndex === index) {
      sorting = sortOrder === 'asc' ? 'desc' : 'asc'
    } else {
      sorting = initialOrder === 'desc' ? 'asc' : 'desc'
    }

    setSortOrder(sorting)
    setSortIndex(index)
    let toSort = [...rows]
    toSort.sort((a, b) => {
      if (sorting === 'asc')
        return a[columns[index].sortableValue] < b[columns[index].sortableValue]
          ? -1
          : a[columns[index].sortableValue] > b[columns[index].sortableValue]
          ? 1
          : 0

      return a[columns[index].sortableValue] < b[columns[index].sortableValue]
        ? 1
        : a[columns[index].sortableValue] > b[columns[index].sortableValue]
        ? -1
        : 0
    })
    setRows(toSort)
  }
  const sortAsync = async (index) => {
    /* TO-DO */
  }
  const sort = (index) => {
    if (!fetchUrl) return sortSync(index)

    sortAsync(index)
  }

  /* SEARCH FUNCTION */
  const searchSync = (e) => {
    if (e.target.value.trim() === '') {
      setPage(1)
      setTotalPages(Math.ceil(data.length / rowsPerPage))
      setSearchRows(null)
      return setSearchString('')
    }

    setSearchString(e.target.value)
    let newRows = rows.filter((row) => {
      let retorno = false
      for (let value in row) {
        if (
          typeof row[value] === 'string' &&
          row[value].toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
        ) {
          retorno = true
          return true
        } else if (typeof row[value] === 'number' && row[value] == e.target.value) {
          retorno = true
          return true
        }
      }
      return retorno
    })
    setSearchRows(newRows)
    setPage(1)
    setTotalPages(Math.ceil(newRows.length / rowsPerPage))
  }
  const searchAsync = async (e) => {
    /* TO-DO */
  }
  const search = (e) => {
    if (!fetchUrl) return searchSync(e)

    searchAsync(e)
  }

  /* FETCH ASYNC DATA */
  const fetchData = async () => {
    //TO-DO
  }

  /* SET DATA */
  const setData = () => {
    setRows(data)
    setPage(1)
    setTotalPages(Math.ceil(data.length / rowsPerPage))
    setLoading(false)
  }

  /* CHECK IF SHOULD SEARCH OR IF IT HAS DATA */
  useEffect(() => {
    if (data) return setData()

    if (fetchUrl) return fetchData()

    setRows(initialRows(loadingRowsNumber, columns))
  }, [data])

  /* RENDER ACTUAL PAGE */
  const renderActualPage = () => {
    let data = searchRows !== null ? searchRows : rows

    let initialIndex = (page - 1) * rowsPerPage

    let toRender = []

    console.log(data)
    for (let i = 0; i < rowsPerPage; i++) {
      let index = initialIndex + i
      if (isValid(data[index])) toRender.push(data[index])
    }

    return (
      <>
        {toRender.map((item, index) => (
          <Row
            key={`table-row-${index}`}
            row={item}
            rowModel={rowModel}
            columns={columns}
            loading={loading}
            index={index}
          />
        ))}
      </>
    )
  }

  const renderPages = (pages) => {
    return (
      <>
        <li className={`page-item previous ${page === 1 ? 'disabled' : ''}`}>
          <a href='javascript:void(0)' onClick={() => setPage(page - 1)} className='page-link'>
            <i className='previous'></i>
          </a>
        </li>
        {pages.map((pg) => (
          <li className={`page-item ${pg === page ? 'active' : ''}`}>
            <a href='javascript:void(0)' onClick={() => setPage(pg)} className='page-link'>
              {pg}
            </a>
          </li>
        ))}
        <li className={`page-item next ${page === totalPages ? 'disabled' : ''}`}>
          <a href='javascript:void(0)' onClick={() => setPage(page + 1)} className='page-link'>
            <i className='next'></i>
          </a>
        </li>
      </>
    )
  }

  const getPaginationWhenLowerThanFive = () => {
    let pages = []
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i)
    }

    return renderPages(pages)
  }

  const getPaginationWhenBiggerThanFive = () => {
    let pages = []
    let begins = page
    if (begins + 2 > totalPages) begins = totalPages - 2
    let init = begins - 2 >= 1 ? begins - 2 : 1;
    let end = init + 4;
    for (let i = init; i <= end; i++) {
      pages.push(i)
    }

    return renderPages(pages)
  }

  return (
    <div className='container-table d-flex flex-column align-items-end w-100'>
      {hasSearch && (
        <div className='search-container form-group'>
          <input
            type='text'
            className='form-control'
            placeholder='Buscar...'
            value={searchString}
            onChange={search}
          />
        </div>
      )}
      <div className='table-responsive' style={{width: '100%'}}>
        {/* begin::Table */}
        <table
          className={`table custom-table table-row-bordered table-row-primary align-middle gs-0 gy-4`}
        >
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              {columns.map((column, index) => (
                <Column
                  key={`table-column-${index}`}
                  column={column}
                  index={index}
                  sortIndex={sortIndex}
                  sortOrder={sortOrder}
                  sort={sort}
                  initialOrder={initialOrder}
                />
              ))}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {loading ? (
              <>
                {rows.map((item, index) => (
                  <Row
                    key={`table-row-${index}`}
                    row={item}
                    rowModel={rowModel}
                    columns={columns}
                    loading={loading}
                    index={index}
                  />
                ))}
              </>
            ) : (
              <>{renderActualPage()}</>
            )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
        {!rows && (
          <div
            className='d-flex p-4 justify-content-center'
            style={{width: '100%', display: 'flex', flex: 1}}
          >
            <CircularProgress size={14} className='me-2' /> Buscando unilevel...
          </div>
        )}
        {rows && rows.length === 0 && (
          <div className='alert alert-danger' style={{width: '100%', display: 'flex', flex: 1}}>
            Nenhuma entrada encontrada
          </div>
        )}
      </div>
      {paginate && (
        <ul className='pagination'>
          {totalPages < 5 ? (
            <>{getPaginationWhenLowerThanFive()}</>
          ) : (
            <>{getPaginationWhenBiggerThanFive()}</>
          )}
        </ul>
      )}
    </div>
  )
}

export default Table
