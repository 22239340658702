import React, {useState, useEffect, useContext} from 'react'
import {Paper, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
// import "./index.css";
import PersonIcon from '@material-ui/icons/Person'
import CircularProgress from '@material-ui/core/CircularProgress'
import Popper from '@material-ui/core/Popper'
import {sendRequest} from '../../Helpers/Requests'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

const backgroundStyles = {
  width: '80px',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '15px',
}
const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 'bold',
  },
  iconePerson: {
    fontSize: '50px',
    width: '80px',
    height: '80px',
    padding: '20px',
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    border: '2px solid ' + getCSSVariableValue('--kt-danger'),
  },
  iconePersonHasPerson: {
    fontSize: '50px',
    width: '80px',
    height: '80px',
    padding: '0px',
    cursor: 'pointer',
    background: 'transparent',
    color: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '15px',
    border: '2px solid ' + getCSSVariableValue('--kt-primary'),
  },
  background: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    ...backgroundStyles,
  },
  backgroundHasPerson: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    ...backgroundStyles,
    border: '2px solid ' + getCSSVariableValue('--kt-primary'),
  },
  nomeUser: {
    display: 'flex',
    position: 'absolute',
    top: '-27px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
    borderRadius: '10px',
    zIndex: '0',
    fontSize: '1.15rem',
    fontWeight: 'bold',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '0rem .4rem',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.4)',
  },
  paper: {
    border: '2px solid ' + getCSSVariableValue('--kt-primary'),
    padding: theme.spacing(1),
    backgroundColor: 'rgba(20, 20, 35, 0.9)',
    borderRadius: '0.25rem',
    color: getCSSVariableValue('--kt-primary'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
  },
}))

function RedeUser({index, popperPlacement, clickUser, rede, setRede}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [esquerdaDireita, setEsquerdaDireita] = useState([
    <CircularProgress size='1rem' />,
    <CircularProgress size='1rem' />,
  ])

  const retornaNomeClasse = () => {
    return 'HasPerson'
  }

  const open = Boolean(anchorEl)

  const idpopper = () => {
    if (!open || rede.length < index || !rede[index].id) return undefined

    return 'dados-user-' + rede[index].id
  }

  const handleMouseOver = async (event) => {
    setAnchorEl(event.currentTarget)

    if (rede[index].users_esquerda !== '' && rede[index].users_direita !== '') {
      setEsquerdaDireita([rede[index].users_esquerda, rede[index].users_direita])

      return true
    }

    const dados = await sendRequest(`rede/total_usuarios/${rede[index].id}`, 'GET')

    let redeAtual = [...rede]
    redeAtual[index].users_esquerda = dados.formato.esquerda
    redeAtual[index].users_direita = dados.formato.direita
    setRede([...redeAtual])
    setEsquerdaDireita([dados.formato.esquerda, dados.formato.direita])
  }

  const handleMouseOut = () => {
    setEsquerdaDireita([<CircularProgress size='1rem' />, <CircularProgress size='1rem' />])
    setAnchorEl(null)
  }

  useEffect(() => {}, [])

  const handleClickUser = () => {
    if (rede.length > index) clickUser(rede[index])
  }
  return rede.length > index && rede[index].id ? (
    <div className='Rede--coluna_user'>
      <div className='Rede--user'>
        <Paper
          elevation={3}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleClickUser}
          className={classes['background' + retornaNomeClasse()]}
          style={{
            backgroundImage: `url('${rede[index].img}')`,
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
          }}
        >
          <Typography variant='h5' className={[classes['nomeUser'], 'notranslate']}>
            {rede[index].login}
          </Typography>
        </Paper>
        <Popper id={idpopper()} open={open} placement={popperPlacement} anchorEl={anchorEl}>
          <div className={classes.paper}>
            <div className='d-flex w-100 justify-content-center'>
              <b>Plano:</b>

              {rede[index].plano ? (
                <span className='notranslate ps-3'>{rede[index].plano}</span>
              ) : (
                <div className='ps-3'>Não possui</div>
              )}
            </div>
            <div className='d-flex justify-content-between w-100'>
              <div>
                <b>Pontos Esquerda:</b><span className='notranslate ps-3'>{rede[index].pontos_esquerda}</span>
              </div>
              <div>
                <b>Pontos Direita:</b><span className='notranslate ps-3'>{rede[index].pontos_direita}</span>
              </div>
            </div>
            <div className='d-flex justify-content-between w-100'>
              <div>
                <b>Usuários Esquerda:</b><span className='notranslate ps-3'>{esquerdaDireita[0]}</span>
              </div>
              <div>
                <b>Usuários Direita:</b><span className='notranslate ps-3'>{esquerdaDireita[1]}</span>
              </div>
            </div>
          </div>
        </Popper>
      </div>
    </div>
  ) : (
    <div className='Rede--coluna_user' style={{borderColor: '#0084f7'}}>
      <div className='Rede--user'>
        <Paper elevation={3} className={classes['background']}>
          <PersonIcon className={classes['iconePerson']} />
          <Typography variant='h5' className={classes['nomeUser']}></Typography>
        </Paper>
      </div>
    </div>
  )
}

export default RedeUser
