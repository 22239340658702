import {useEffect, useState, useContext} from 'react'
import {sendRequest} from '../../../Helpers/Requests'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import ConfigContext from '../../contexts/Config/ConfigContext'
import {CircularProgress} from '@material-ui/core'
import Table from '../../modules/table/Table'
import {getColumns} from './indicados/columns'
import {rowModel} from './indicados/rows'

const MeusIndicados = () => {
  const config = useContext(ConfigContext)
  const [diretos, setDiretos] = useState<any>(null)

  const getDiretos = async () => {
    const req = await sendRequest(`rede/diretos`, 'GET')

    const response = await req

    if (response.tipo == 'success') {
      setDiretos(response.unilevel)
    }
  }

  const renderAvatar = (avatarUrl: any, genero: string | null) => {
    let img = avatarUrl

    if (avatarUrl === null) {
      if (genero === 'F') {
        img = toAbsoluteUrl('/media/avatars/300-14.jpg')
      } else if (genero === 'M') {
        img = toAbsoluteUrl('/media/avatars/300-1.jpg')
      }
    }
    return <img src={img} alt='' />
  }

  const renderRows = (item: any, key: any) => {
    return (
      <tr key={key}>
        <td className='notranslate'>#{item.id}</td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px me-5'>{renderAvatar(item.imagem, item.sexo)}</div>
            <div className='d-flex justify-content-start flex-column'>
              <a href='#' className='text-dark fw-bold text-hover-primary fs-6 notranslate'>
                {item.nome}
              </a>
              <span className='text-muted fw-semibold text-muted d-block fs-7 notranslate'>
                {item.login}
              </span>
            </div>
          </div>
        </td>
        <td>
          {item.ativo == 1 && item.bloqueado == 0 && (
            <div className='badge badge-success'>Ativo</div>
          )}

          {item.ativo == 0 && item.bloqueado == 1 && (
            <div className='badge badge-danger'>Inativo</div>
          )}
        </td>
        {config.binario && (
          <td>
            <div className='badge badge-info'>{item.perna}</div>
          </td>
        )}
      </tr>
    )
  }

  useEffect(() => {
    getDiretos()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Meus indicados</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush'>
            <div className='card-body d-flex align-items-end pt-5'>
              <Table columns={getColumns(config.binario)} data={diretos} rowModel={rowModel} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {MeusIndicados}
