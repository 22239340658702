import axios from 'axios'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import {nomeVariaveis} from './Utils'
import {getAuth} from '../app/modules/auth'
import {verifyErrors} from './axiosErrors'

const enviroment = process.env.REACT_APP_ENVIROMENT
const apiProductionUrl = process.env.REACT_APP_API_URL
const backofficeProductionUrl = process.env.REACT_APP_BACKOFFICE_URL

export const baseUri = enviroment === 'sandbox' ? 'http://localhost:8000' : apiProductionUrl
export const baseFrontUri =
  enviroment === 'sandbox' ? 'http://localhost:3011' : backofficeProductionUrl

export const baseUrl = (endpoint = '') => {
  if (endpoint.includes(baseUri)) {
    return endpoint
  }

  return endpoint[0] === '/' ? `${baseUri + endpoint}` : `${baseUri}/${endpoint}`
}
export const frontUrl = (endpoint) => {
  if (endpoint.includes(baseFrontUri)) return endpoint
  return `${baseFrontUri}/${endpoint}`
  // return `http://drive.studiorael.com.br/${endpoint}`
}
export const base_api_url = (urlParams = '') => {
  const params = urlParams !== '' ? `/${urlParams}` : '/'
  return `${baseUrl()}api${params}`
}

function getToken() {
  try {
    const auth = getAuth()
    const token = auth.api_token
    return token
  } catch (error) {
    return null
  }
}

axios.defaults.baseURL = base_api_url()

axios.interceptors.response.use(
  (request) => {
    return request
  },
  (error) => {
    verifyErrors(error)
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  (request) => {
    const jToken = getToken()
    if (!jToken) return request

    request.headers.Authorization = `Bearer ${jToken}`

    return request
  },
  (error) => {
    Promise.reject(error)
  }
)

export const requestUser = async (token) => {
  return await sendRequest(
    'usuario',
    'GET',
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  )
}

export const sendRequest = async (
  url,
  method = 'POST',
  data = {},
  headers = null,
  responseType = null
) => {
  const sendUrl = url[0] === '/' ? url : `/${url}`
  const config = {
    method,
    url: sendUrl,
  }

  if (data !== null) config.data = data
  if (headers !== null) config.headers = headers
  if (responseType !== null) config.responseType = responseType

  const response = await axios(config)

  if (response === null || typeof response === 'undefined') {
    return null
  }

  if (typeof response.data === 'undefined') {
    return response
  }

  if (response.data.msg !== '') {
    if (response.data.tipo === 'error') {
      if (response.data.payment_link === undefined) {
        toast.error(response.data.msg)
      }
    } else {
      toast.success(response.data.msg)
    }
  }
  return response.data
}
