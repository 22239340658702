import React from 'react'
import {openInNewTab} from '../../Helpers/Utils'
import {sendRequest} from '../../Helpers/Requests'
import {toast} from 'react-hot-toast'

const useSubscriptionPayment = () => {
  const handlePayment = async (
    url: string,
    button: any,
    onStart: Function | null,
    callBack: Function | null,
    appends: any = {},
    pixMessage: string = 'Após a conclusão do pagamento, por favor, envie o comprovante para que possamos agilizar a ativação do seu plano!'
  ) => {
    if (typeof onStart === 'function') onStart()
    const response = await sendRequest(url, 'POST', {
      coin: button.coin,
      method: button.method,
      type: button.type,
      ...appends,
    })

    if (response.tipo === 'success') {
      if (typeof response.payment_link === 'string') openInNewTab(response.payment_link)
    }

    if (button.method === 'Pix') {
      toast.success(pixMessage)
    }
    if (typeof callBack === 'function') return callBack(response)

    return response;
  }

  const paySubscription = async (
    subscription_id: string | bigint,
    button: any,
    onStart: Function | null = null,
    callBack: Function | null = null
  ) => {
    const url: string = `planos/pagar/${subscription_id}`

    const response = await handlePayment(url, button, onStart, callBack)

    return response;
  }

  const buyPlan = async (
    plan_id: string | bigint,
    button: any,
    onStart: Function | null = null,
    callBack: Function | null = null
  ) => {
    const url: string = `planos/comprar`

    const response = await handlePayment(url, button, onStart, callBack, {plano: plan_id})
    
    return response;
  }

  const payLicence = async (
    user_id: string | bigint,
    button: any,
    onStart: Function | null = null,
    callBack: Function | null = null
  ) => {
    const url: string = `licenca/${user_id}`

    const response = await handlePayment(url, button, onStart, callBack)
    return response;
  }

  const payLicenceOnRegister = async (
    userData: any,
    button: any,
    onStart: Function | null = null,
    callBack: Function | null = null
  ) => {
    const url: string = `usuario/cadastrar`

    const response = await handlePayment(url, button, onStart, callBack, userData, "Faça a transferência e envie o comprovante para que possamos agilizar sua ativação!")
    return response;
  }

  return {paySubscription, buyPlan, payLicenceOnRegister, payLicence}
}

export {useSubscriptionPayment}
