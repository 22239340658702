function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const rowModel = (row) => {
  return [
    {
      value: `#${row.id}`,
      translatable: false,
    },
    {
      value: `$${parseFloat(row.valor).toFixed(2)}`,
      translatable: false,
    },
    {
      value: `${row.tipo === 'binario' ? 'Binário' : row.tipo === 'diario' ? 'Diário' : row.tipo === 'indicacao' ? 'Indicação' : ucfirst(row.tipo)}`,
    },
    {
      value: row.descricao,
    },
    {
      render: () => (
        <>
          {(row.balanco === 'entrada' && <span className='badge badge-success'>Entrada</span>) || (
            <span className='badge badge-danger'>Saída</span>
          )}
        </>
      ),
    },
    {
      value: row.created_at,
    },
  ]
}
