import {PageTitle} from '../../../_metronic/layout/core'
import {useState, useEffect, useContext} from 'react'
import {sendRequest} from '../../../Helpers/Requests'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {toast} from 'react-hot-toast'
import {openInNewTab} from '../../../Helpers/Utils'
import {ModalPagamento} from './ModalPagamento'
import {useSubscriptionPayment} from '../../hooks/useSubscriptionPayment'
import {ModalCancelamento} from './ModalCancelamento'
import {AssinaturaRows} from './AssinaturaRows'
import {ModalComprovante} from './ModalComprovante'
import ConfigContext from '../../contexts/Config/ConfigContext'

const MinhaAssinatura = () => {
  const subscriptionPayment = useSubscriptionPayment()
  const [rows, setRows] = useState([])
  const [signature, setSignature] = useState<any>(null)
  const [paymentLinks, setPaymentLinks] = useState<any>(null)

  const [subscription, setSubscription] = useState<any>(null)
  const [paymodal, setPaymodal] = useState<boolean>(false)
  const [paymentProofModal, setPaymentProofModal] = useState<boolean>(false)
  const [loadPayment, setLoadPayment] = useState<boolean>(false)

  const config = useContext(ConfigContext)

  const paySubscription = (item: any) => {
    setSubscription(item)
    setPaymodal(true)
  }

  const sendProofOfPayment = (item: any) => {
    setSubscription(item)
    setPaymentProofModal(true)
  }

  const hidePaymodal = () => {
    setPaymodal(false)
  }

  const hidePaymentProofModal = () => {
    setPaymentProofModal(false)
    setSubscription(null)
  }

  const loadMySignatures = async () => {
    const req = await sendRequest('planos/pedidos', 'GET')
    const response = await req

    if (response.tipo === 'success') {
      setRows(response.planos)
    }
  }

  const handleCancel = async () => {
    if (signature != null) {
      const req = await sendRequest(`planos/remover/${signature.id}`, 'DELETE')
      const response = await req

      if (response.tipo === 'success') {
        setSignature(null)
        loadMySignatures()
      }
    } else {
      toast.error('Você precisa selecionar uma assinatura para cancelar.')
    }
  }

  const handlePayment = async (button: any) => {
    setLoadPayment(true)
    await subscriptionPayment.paySubscription(subscription.id, button)
    await loadMySignatures()
    hidePaymodal()
    setSubscription(null)
    setLoadPayment(false)
  }

  useEffect(() => {
    loadMySignatures()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Minha assinatura</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush'>
            <div className='card-body d-flex align-items-end pt-5'>
              <div className='table-responsive' style={{width: '100%'}}>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th>ID</th>
                      <th>Assinatura</th>
                      <th>Estado</th>
                      <th>Valor</th>
                      <th>Recebido</th>
                      <th>Ativado Em</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {rows.length > 0 &&
                      rows.map((item, key) => (
                        <AssinaturaRows
                          item={item}
                          key={key}
                          setSignature={setSignature}
                          sendProofOfPayment={sendProofOfPayment}
                          paySubscription={paySubscription}
                          config={config}
                        />
                      ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
                {rows.length === 0 && (
                  <div
                    className='alert alert-info'
                    style={{width: '100%', display: 'flex', flex: 1}}
                  >
                    Você não possui nenhuma assinatura
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalComprovante
        paymentProofModal={paymentProofModal}
        hidePaymentProofModal={hidePaymentProofModal}
        subscription={subscription}
        config={config}
      />

      <ModalCancelamento
        signature={signature}
        setSignature={setSignature}
        handleCancel={handleCancel}
        config={config}
      />

      <ModalPagamento
        paymodal={paymodal}
        hidePaymodal={hidePaymodal}
        subscription={subscription}
        handlePayment={handlePayment}
        loadPayment={loadPayment}
        setSubscription={setSubscription}
        config={config}
      />
    </>
  )
}

export {MinhaAssinatura}
