import {useState, useEffect, useContext} from 'react'
import {FormControl, Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useSubscriptionPayment} from '../../../hooks/useSubscriptionPayment'
import {toast} from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {baseUrl, sendRequest} from '../../../../Helpers/Requests'
import {setDefaultResultOrder} from 'dns'
import ConfigContext from '../../../contexts/Config/ConfigContext'
import {useCopyToClipboard} from 'usehooks-ts'
import {ModalComprovanteCadastro} from './ModalComprovanteCadastro'

const ModalPixSend = (props: any) => {
  const [value, copy] = useCopyToClipboard()
  const config = useContext(ConfigContext)
  const [paymentProofModal, setPaymentProofModal] = useState(false)
  const {valor, pixModal, hidePixModal, user = null, licenca = null, redirectAfter = null} = props

  const handleCopyLink = () => {
    copy(config.pix_chave)
    toast.success('chave copiada com sucesso!')
  }

  const handlePaymentProof = () => {
    hidePixModal()
    return setPaymentProofModal(true)
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={pixModal}
        onHide={hidePixModal}
        backdrop={true}
      >
        <div className='modal-header'>
          <h2>Pagamento via PIX</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hidePixModal}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body py-lg-5 px-lg-10'>
          <div className='row mt-0'>
            <div className='alert alert-warning col-lg-12'>
              Para concluir seu pagamento, envie um PIX no valor de{' '}
              <b>${parseFloat(valor).toFixed(2)}</b>
              <br />
              Para os dados informados abaixo:
            </div>
          </div>
          <h5>
            <b>Nome do destinatário: </b> {config.pix_nome}
          </h5>
          {config.pix_qrcode && (
            <div className='row justify-content-center'>
              <div className='col-12 d-flex justify-content-center'>
                <img src={baseUrl(config.pix_qrcode)} style={{maxWidth: '200px'}} />
              </div>
            </div>
          )}
          <div className='row w-100'>
            <div className='col-lg-10'>
              <FormControl readOnly={true} value={'Chave: ' + config.pix_chave} />
            </div>
            <div className='col-lg-1'>
              <div className='d-grid'>
                <button
                  onClick={handleCopyLink}
                  className='btn btn-success btn-icon-success btn-text-default'
                >
                  Copiar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='modal-footer py-lg-10 px-lg-10'>
          <button className='btn btn-primary' style={{width: '100%'}} onClick={handlePaymentProof}>
            Enviar Comprovante
          </button>
          <button className='btn btn-danger' style={{width: '100%'}} onClick={hidePixModal}>
            Cancelar
          </button>
        </div>
      </Modal>

      <ModalComprovanteCadastro
        paymentProofModal={paymentProofModal}
        hidePaymentProofModal={() => setPaymentProofModal(false)}
        user={user}
        licenca={licenca}
        redirectAfter={redirectAfter}
      />
    </>
  )
}

export {ModalPixSend}
