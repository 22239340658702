import {useState, useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useSubscriptionPayment} from '../../../hooks/useSubscriptionPayment'
import {toast} from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {sendRequest} from '../../../../Helpers/Requests'
import {setDefaultResultOrder} from 'dns'
import {ModalPixSend} from './ModalPixSend'

const ModalPagamentoCadastro = (props: any) => {
  const navigate = useNavigate()
  const [pixModal, setPixModal] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const subscriptionPayment = useSubscriptionPayment()

  const {
    paymodal,
    hidePaymodal,
    licenca,
    paymentButtons,
    user,
    setUser = () => {},
    userData = null,
    userId = null,
  } = props

  const pagamentoCadastro = async (button: any, index: any) => {
    setLoadingPayment(true)

    try {
      const response = await subscriptionPayment.payLicenceOnRegister(userData, button)

      if (!response) {
        setLoadingPayment(false)
        return false
      }

      if (button.method === 'Pix') {
        setUser(response.user)
        hidePaymodal()
        setLoadingPayment(false)
        return setPixModal(true)
      }

      toast.success('Por favor, finalize o pagamento da sua licença para ter acesso à plataforma!')
      navigate('/auth')
    } catch (e) {
      setLoadingPayment(false)
    }
  }

  const gerarLinkDePagamento = async (button: any, index: any) => {
    if (userData !== null) return pagamentoCadastro(button, index)

    setLoadingPayment(true)

    try {
      const response = await subscriptionPayment.payLicence(userId, button)
      if (!response) {
        setLoadingPayment(false)
        return false
      }
      if (button.method === 'Pix') {
        setUser(response.user)
        hidePaymodal()
        setLoadingPayment(false)
        return setPixModal(true)
      }

      setLoadingPayment(false)
      hidePaymodal()
    } catch (e) {
      setLoadingPayment(false)
    }
  }

  if (!licenca) return <></>

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={paymodal}
        onHide={hidePaymodal}
        backdrop={true}
      >
        <div className='modal-header'>
          <h2>Pagar Licença</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hidePaymodal}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body py-lg-5 px-lg-10'>
          <div className='row mt-0'>
            <div className='alert alert-primary col-lg-12'>
              <b>Falta pouco!</b>
              <br />
              Para finalizar seu cadastro, basta pagar a licença de uso da plataforma no{' '}
              <b>
                valor de : <span className='notranslate'>${licenca.toFixed(2)}</span>
              </b>
              <br />
              <br />
              Com um dos métodos de pagamento abaixo:
            </div>
          </div>
          {licenca && paymentButtons && (
            <div className='row mt-2'>
              <div className='col-lg-12 d-flex flex-column align-items-center justify-content-between'>
                {paymentButtons.map((btn: any, index: any) => (
                  <button
                    key={`btn${index}`}
                    type='reset'
                    title={btn.title}
                    className={'btn btn-info btn-block btn-sm w-100 me-3 mb-3'}
                    onClick={() => gerarLinkDePagamento(btn, index)}
                    disabled={loadingPayment}
                  >
                    {loadingPayment ? (
                      <>
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Aguarde um instante...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </>
                    ) : (
                      <>
                        <img src={btn.prepend_image} style={{height: '25px'}} />
                        <span className='ms-3'>{btn.title}</span>
                      </>
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className='modal-footer py-lg-10 px-lg-10'>
          <button className='btn btn-danger' style={{width: '100%'}} onClick={hidePaymodal}>
            Cancelar
          </button>
        </div>
      </Modal>
      <ModalPixSend
        valor={licenca}
        pixModal={pixModal}
        hidePixModal={() => setPixModal(false)}
        licenca={licenca}
        user={user}
        redirectAfter={'/auth'}
      />
    </>
  )
}

export {ModalPagamentoCadastro}
