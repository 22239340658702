export const isString = (element) => {
  return typeof element === 'string'
}

export const isNumeric = (element) => {
  return typeof element === 'number'
}

export const isObject = (element) => {
  return typeof element === 'object'
}

export const isFunction = (element) => {
  return typeof element === 'function'
}

export const exists = (element) => {
  return typeof element !== 'undefined' && element !== null
}

export const existsKey = (element, key) => {
  if (isString(key)) return exists(element) && exists(element[key])

  if (Array.isArray(key)) {
    if (!isValid(element)) return false
    let valid = true
    key.forEach((k) => {
      valid = valid && exists(element[k])
    })

    return valid
  }

  return false
}

export const isValid = (element) => {
  return typeof element !== 'undefined' && element !== null
}

export const isValidKey = (element, key) => {
  if (isString(key)) return isValid(element) && isValid(element[key])

  if (Array.isArray(key)) {
    if (!isValid(element)) return false
    let valid = true
    key.forEach((k) => {
      valid = valid && isValid(element[k])
    })

    return valid
  }

  return false
}

export const getEmptyRow = (columns) => {
  let row = []
  columns.forEach((col) => {
    row.push({
      value: '',
    })
  })
  return row
}
export const initialRows = (rowsPerPage, columns) => {
  let actualRows = []
  for (let i = 0; i < rowsPerPage; i++) actualRows.push(getEmptyRow(columns))
  return actualRows
}
