// import { DefaultRoute } from "../router/routes"

export const nomeVariaveis = () => {
  return "piramide-iguana@#2023"
}
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
// export const getUserData = () => localStorage.getItem(`@${nomeVariaveis()}_userdata`)

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = (userRole) => {
//   if (userRole === "admin") return DefaultRoute
//   if (userRole === "client") return "/access-control"
//   return "/login"
// }

// ** React Select Theme Colors
// export const selectThemeColors = (theme) => ({
//   ...theme,
//   colors: {
//     ...theme.colors,
//     primary25: "#7367f01a", // for option hover bg-color
//     primary: "#7367f0", // for selected option bg-color
//     neutral10: "#7367f0", // for tags bg-color
//     neutral20: "#ededed", // for input border-color
//     neutral30: "#ededed" // for input hover border-color
//   }
// })


export const formatUsers = (dados) => {
  let usuarios = [];

  for (let i = 0; i <= 3; i++) {
    if (typeof dados.formato.users["node_" + i] !== "undefined") {
      if (typeof dados.formato.users["node_" + i].length !== "undefined") {
        for (let j = dados.formato.users["node_" + i].length - 1; j >= 0; j--) {
          let userAux = dados.formato.users["node_" + i][j];
          userAux.users_esquerda = "";
          userAux.users_direita = "";
          usuarios.push(userAux);
        }
      } else {
        let userAux = dados.formato.users["node_" + i];
        userAux.users_esquerda = "";
        userAux.users_direita = "";
        usuarios.push(userAux);
      }
    }
  }

  return usuarios;
}

export const openInNewTab = (href) => {
  Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
  }).click();
}

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const fileToBase64WithoutHeadings = async (file) => {
  let data = await convertFileToBase64(file);
  data = data.split('base64,');
  if (typeof data[1] !== 'undefined') return data[1];
  return null;
}
