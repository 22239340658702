import React from 'react'
import clsx from 'clsx'
import useGTranslator from '../hooks/useGTranslator'
import { useAuth } from '../../auth'

const LanguageSelector = ({
  containerClassName = 'form-group',
  labelClassName = 'form-label',
  selectClassName = 'form-select form-gTranslate bg-transparent',
  optionLabelFullDescription = true,
  loginPage = false,
}) => {
  const {currentUser} = useAuth()
  const {changeLang, getLangs, getActualValue} = useGTranslator()
  const [langs, setLangs] = React.useState(null)
  const [actualValue, setActualValue] = React.useState(null)
  const [widthClass, setWidthClass] = React.useState(null)
  React.useEffect(() => {
    setLangs(getLangs())
    setActualValue(getActualValue())
    setWidthClass(optionLabelFullDescription ? (loginPage ? 'w-300px' : 'w-200px') : 'w-55px')
  }, [currentUser])

  if (!langs || !actualValue || !widthClass) return <></>

  return (
    <div className={containerClassName}>
      <div className={'app-navbar-item'}>
        <div
          className={'cursor-pointer d-flex w-100 justify-content-center align-items-center g-3'}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement={optionLabelFullDescription ? 'bottom-end' : 'bottom-start'}
        >
          <div className='symbol symbol-35px symbol-md-40px'>
            <img className='rounded-1' src={actualValue.flag} alt={actualValue.label} />
          </div>
          {optionLabelFullDescription && (
            <div className='d-flex flex-column ps-3'>
              <div className='fw-bolder d-flex align-items-center fs-5 notranslate'>
                {actualValue.label}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 ' +
            widthClass
          }
          data-kt-menu='true'
        >
          {langs.map((lang, index) => (
            <div
              onClick={() => changeLang(lang.value)}
              className='menu-item cursor-pointer d-flex w-100 align-items-center px-2 mb-3'
              key={`lang-option-${index}`}
            >
              <div className='symbol symbol-35px symbol-md-40px'>
                <img className='rounded-1' src={lang.flag} alt={lang.label} />
              </div>
              {optionLabelFullDescription && (
                <div className='d-flex flex-column ps-3'>
                  <div className='fw-bolder d-flex align-items-center fs-5 notranslate'>
                    {lang.label}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LanguageSelector
