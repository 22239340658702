import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { sendRequest } from '../../../Helpers/Requests'

const AtivarCarteira = () => {
    const { codigo } = useParams()

    const activeWallet = async () => {
        const req = await sendRequest('carteiras/ativar', 'POST', {
            codigo
        })
        const response = await req

        if (response.tipo !== 'success') {
            window.location.href = '/dashboard'
        }
    }

    useEffect(() => {
        activeWallet()
    }, [])

    return(<>ativar Carteira</>)
}

export { AtivarCarteira }